import { ChainId } from '@pancakeswap/chains'
import { supportedChainId } from '@pancakeswap/farms'

export const SUPPORT_ONLY_BSC = [ChainId.PULSECHAIN]
export const SUPPORT_FARMS = supportedChainId
export const LIQUID_STAKING_SUPPORTED_CHAINS = []
export const FIXED_STAKING_SUPPORTED_CHAINS = []

export const V3_MIGRATION_SUPPORTED_CHAINS = []

export const SUPPORT_CAKE_STAKING = []
