// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["PULSECHAIN"] = 369] = "PULSECHAIN";
  return ChainId2;
})(ChainId || {});
var testnetChainIds = [];

// src/chainNames.ts
var chainNames = {
  [369 /* PULSECHAIN */]: "pulsechain"
};
var chainNameToChainId = Object.entries(chainNames).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName]: chainId,
    ...acc
  };
}, {});
var defiLlamaChainNames = {
  [369 /* PULSECHAIN */]: "pulse"
};

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getLlamaChainName(chainId) {
  return defiLlamaChainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}
function isTestnetChainId(chainId) {
  if (!chainId)
    return false;
  return false;
}

// src/subgraphs.ts
var V3_SUBGRAPHS = {};
var V2_SUBGRAPHS = {};
var BLOCKS_SUBGRAPHS = getBlocksSubgraphs();
var STABLESWAP_SUBGRAPHS = {
  // [ChainId.PULSECHAIN]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap',
  // [ChainId.ARBITRUM_ONE]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap-arb',
};
function getV3Subgraphs() {
  return {
    /* 
     [ChainId.PULSECHAIN]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc',
    
     [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-eth',
     [ChainId.PULSECHAIN]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc',
     [ChainId.GOERLI]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-goerli',
     [ChainId.PULSECHAIN]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc',
     [ChainId.PULSECHAIN]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-chapel',
     [ChainId.ARBITRUM_ONE]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-arb',
     [ChainId.ARBITRUM_GOERLI]: 'https://api.thegraph.com/subgraphs/name/chef-jojo/exhange-v3-arb-goerli',
     [ChainId.POLYGON_ZKEVM]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-polygon-zkevm/version/latest',
     [ChainId.POLYGON_ZKEVM_TESTNET]: null,
     [ChainId.ZKSYNC]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-zksync/version/latest',
     [ChainId.ZKSYNC_TESTNET]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-zksync-testnet/version/latest',
     [ChainId.LINEA]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-linea/version/latest',
     [ChainId.LINEA_TESTNET]:
       'https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exchange-v3-linea-goerli',
     [ChainId.BASE]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-base/version/latest',
     [ChainId.BASE_TESTNET]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-base-testnet/version/latest',
     [ChainId.OPBNB]: `https://open-platform-ap.nodereal.io/${noderealApiKey}/opbnb-mainnet-graph-query/subgraphs/name/pancakeswap/exchange-v3`,
     [ChainId.OPBNB_TESTNET]: null,
     [ChainId.SCROLL_SEPOLIA]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-scroll-sepolia/version/latest',
     [ChainId.SEPOLIA]: null,
     [ChainId.ARBITRUM_SEPOLIA]: null,
     [ChainId.BASE_SEPOLIA]: null,
     */
    [369 /* PULSECHAIN */]: null
  };
}
function getV2Subgraphs() {
  return {};
}
function getBlocksSubgraphs() {
  return {
    [369 /* PULSECHAIN */]: "https://node.9inch.io/subgraphs/name/nineinch/blocks"
  };
}

export { BLOCKS_SUBGRAPHS, ChainId, STABLESWAP_SUBGRAPHS, V2_SUBGRAPHS, V3_SUBGRAPHS, chainNameToChainId, chainNames, defiLlamaChainNames, getBlocksSubgraphs, getChainIdByChainName, getChainName, getLlamaChainName, getV2Subgraphs, getV3Subgraphs, isTestnetChainId, testnetChainIds };
