import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Secret Squirrel Society',
  defaultTitle: 'Secret Squirrel Society',
  description: 'The Secret Squirrel Society comprises 10,000 distinct collectible Squirrels',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@404Squirrels',
    site: '@404Squirrels',
  },
  openGraph: {
    title: 'The Secret Squirrel Society',
    description: 'The Secret Squirrel Society comprises 10,000 distinct collectible Squirrels',
    images: [{ url: 'https://nftbanners.azureedge.net/banners/banner-sm.png' }],
  },
}
