import { ChainId } from '@pancakeswap/chains'
import { masterChefV3Addresses } from '@pancakeswap/farms'
import { GAUGES_ADDRESS, GAUGES_CALC_ADDRESS } from '@pancakeswap/gauges'
import { ICAKE } from '@pancakeswap/ifos'
import { CAKE_VAULT } from '@pancakeswap/pools'
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router'
import { DEPLOYER_ADDRESSES, NFT_POSITION_MANAGER_ADDRESSES } from '@pancakeswap/v3-sdk'

export default {
  masterChef: {},
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {},
  sousChef: {},
  lotteryV2: {},
  multiCall: {
    [ChainId.PULSECHAIN]: '0x933a4c06A0a78587658553d52B6303eA0E753275',
  },
  nutsProfile: {
    [ChainId.PULSECHAIN]: '0x8a2d9227881915F719b4DFa7425cf7220fb23eEb',
  },
  nuts: {
    [ChainId.PULSECHAIN]: '0x97f7259931f98CC64EbCd993fdE03d71716f3E07',
  },
  pancakeBunnies: {},
  bunnyFactory: {},
  claimRefund: {},
  pointCenterIfo: {},
  bunnySpecial: {},
  tradingCompetitionEaster: {},
  tradingCompetitionFanToken: {},
  tradingCompetitionMobox: {},
  tradingCompetitionMoD: {},
  easterNft: {},
  cakeVault: CAKE_VAULT,
  cakeFlexibleSideVault: {},
  predictionsV1: {},
  bunnySpecialCakeVault: {},
  bunnySpecialPrediction: {},
  bunnySpecialLottery: {},
  bunnySpecialXmas: {},
  farmAuction: {},
  nftMarket: {
    [ChainId.PULSECHAIN]: '0x8443b493e33D09976A88Ad8974E5CFEE8A594cAB',
  },
  reroll: {
    [ChainId.PULSECHAIN]: '0xAa07d441e394daCFcF629f65C4ae8d00db807353',
  },
  nftSale: {
    [ChainId.PULSECHAIN]: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
  },
  pancakeSquad: {
    // [ChainId.PULSECHAIN]: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    // [ChainId.PULSECHAIN]: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {},
  zap: {
    // [ChainId.PULSECHAIN]: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    // [ChainId.PULSECHAIN]: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  stableSwapNativeHelper: {},
  iCake: ICAKE,
  bCakeFarmBooster: {},
  bCakeFarmBoosterProxyFactory: {},
  nonBscVault: {},
  crossFarmingSender: {},
  crossFarmingReceiver: {},
  mmLinkedPool: {},
  tradingReward: {},
  nftPositionManager: NFT_POSITION_MANAGER_ADDRESSES,
  v3PoolDeployer: DEPLOYER_ADDRESSES,
  v3Migrator: {
    [ChainId.PULSECHAIN]: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
  },
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {},
  affiliateProgram: {},
  tradingRewardTopTrades: {},
  vCake: {},
  revenueSharingPool: {},
  anniversaryAchievement: {},
  fixedStaking: {},
  veCake: {},
  gaugesVoting: GAUGES_ADDRESS,
  gaugesVotingCalc: GAUGES_CALC_ADDRESS,
  revenueSharingVeCake: {},
  revenueSharingCakePool: {},
  revenueSharingPoolGateway: {},
} as const satisfies Record<string, Record<number, `0x${string}`>>
