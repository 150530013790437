import { BLOCKS_SUBGRAPHS, ChainId, V2_SUBGRAPHS, V3_SUBGRAPHS } from '@pancakeswap/chains'

export const GRAPH_API_PROFILE = 'https://graph.squirrelsociety.vip/subgraphs/name/squirrelsociety/profile/'

export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://nuts-profile-api.vercel.app'
export const API_NFT = 'https://nuts-nft-api.vercel.app/api/v1'
// export const API_NFT = 'http://localhost:3001/api/v1'

export const IPFS_NFT_JSON = 'https://nuts.mypinata.cloud/ipfs/Qme9gfy7zpKUEa8zEnu2asjehG7H4BTvVFTQx9VaLToVfs/'
export const IPFS_NFT_JPG = 'https://nuts.mypinata.cloud/ipfs/QmZCRn1B2aBLui3z778epuErmpD1svZx9HYQswn4G9TmAs/'

export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/pottery'
// export const ONRAMP_API_BASE_URL = 'https://pcs-onramp-api.com'
export const ONRAMP_API_BASE_URL = 'https://onramp2-api.pancakeswap.com'
export const TRANSAK_API_BASE_URL = 'https://api-stg.transak.com/api/v1'
export const MOONPAY_BASE_URL = 'https://api.moonpay.com'
export const NOTIFICATION_HUB_BASE_URL = 'https://notification-hub.pancakeswap.com'
/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://proxy-worker-api.pancakeswap.com/bsc-exchange'
export const V3_BSC_INFO_CLIENT = `https://open-platform.nodereal.io/${
  process.env.NEXT_PUBLIC_NODE_REAL_API_INFO || process.env.NEXT_PUBLIC_NODE_REAL_API_ETH
}/pancakeswap-v3/graphql`

export const INFO_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth'
export const BLOCKS_CLIENT = BLOCKS_SUBGRAPHS[ChainId.PULSECHAIN]

export const GRAPH_API_NFTMARKET = 'https://graph.squirrelsociety.vip/subgraphs/name/squirrelsociety/nftmarketplace'
export const GRAPH_SQUIRRELS = 'https://graph.squirrelsociety.vip/subgraphs/name/squirrelsociety/nutscollection'

// export const GRAPH_SQUIRRELS = 'http://localhost:8000/subgraphs/name/squirrelsociety/nutscollection'
// export const GRAPH_API_NFTMARKET = 'http://localhost:8000/subgraphs/name/squirrelsociety/nftmarket'

export const GRAPH_HEALTH = 'https://graph.squirrelsociety.vip/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = 'https://red.alert.pancakeswap.com/red-api'

export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = V2_SUBGRAPHS

export const BLOCKS_CLIENT_WITH_CHAIN = BLOCKS_SUBGRAPHS

export const PULSE_SCAN_URL =
  'https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/'

export const ASSET_CDN = 'https://nftbanners.azureedge.net'
export const ASSET_CDN_IMG = 'https://nftbanners.azureedge.net/image-file/'
export const ASSET_CDN_JSON = 'https://nftbanners.azureedge.net/image-metadata/'

export const V3_SUBGRAPH_URLS = V3_SUBGRAPHS

export const TRADING_REWARD_API = 'https://trading-reward.pancakeswap.com/api/v1'

export const QUOTING_API = `${process.env.NEXT_PUBLIC_QUOTING_API}/order-price/get-price`

export const FARMS_API = 'https://farms-api.pancakeswap.com'

export const MERCURYO_WIDGET_ID = process.env.NEXT_PUBLIC_MERCURYO_WIDGET_ID || '64d1f9f9-85ee-4558-8168-1dc0e7057ce6'

export const MOONPAY_API_KEY = process.env.NEXT_PUBLIC_MOONPAY_LIVE_KEY || 'pk_test_1Ibe44lMglFVL8COOYO7SEKnIBrzrp54'

export const TRANSAK_API_KEY = process.env.NEXT_PUBLIC_TRANSAK_LIVE_KEY || 'bf960e79-6d98-4fd0-823d-8409d290c346'
// no need for extra public env
export const MERCURYO_WIDGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://sandbox-widget.mrcr.io/embed.2.0.js'
    : 'https://widget.mercuryo.io/embed.2.0.js'

export const WALLET_API = 'https://wallet-api.pancakeswap.com'
