import { useMemo } from 'react'

export const useMenuItemsStatus = (): Record<string, string> => {
  // const ifoStatus = useIfoStatus()
  // const competitionStatus = useCompetitionStatus()
  // const votingStatus = useVotingStatus()
  // const isUserLocked = useUserCakeLockStatus()
  // const tradingRewardStatus = useTradingRewardStatus()

  return useMemo(() => {
    return {
      '/competition': '',
      '/ifo': '',
      '/voting': '',
    }
  }, [])
}
