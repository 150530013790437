import { IPFS_GATEWAY } from '../index'
import { Team } from './types'

const teams: Team[] = [
  {
    id: 1,
    name: 'Seraphim Covenant',
    description:
      'These enlightened squirrels channel their power from divine radiance and unwavering righteousness, seeking to restore balance and peace throughout the ancient forests.',
    images: {
      lg: 'seraphim.jpg',
      md: 'seraphim.jpg',
      sm: 'seraphim.jpg',
      alt: 'seraphim.jpg',
      ipfs: `${IPFS_GATEWAY}/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/syrup-storm.png`,
    },
    background: 'syrup-storm-bg.svg',
    textColor: '#FFFFFF',
    users: 0,
    points: 0,
  },
  {
    id: 2,
    name: 'Legion Covenant',
    description:
      'These corrupted squirrels draw their power from forbidden rituals, aiming to usurp the divine order and plunge the ancient forests into eternal darkness.',
    images: {
      lg: 'legion.jpg',
      md: 'legion.jpg',
      sm: 'legion.jpg',
      alt: 'legion.jpg',
      ipfs: `${IPFS_GATEWAY}/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/fearsome-flippers.png`,
    },
    background: 'fearsome-flippers-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
]

export default teams
