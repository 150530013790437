import { useTranslation } from '@pancakeswap/localization'
import { Flex, Modal, ModalBody } from '@pancakeswap/uikit'
import { useMemo } from 'react'
import { useAllTransactions } from 'state/transactions/hooks'

interface FarmTransactionModalProps {
  onDismiss: () => void
}

const FarmTransactionModal: React.FC<React.PropsWithChildren<FarmTransactionModalProps>> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  // const { pickedTx } = useFarmHarvestTransaction()

  // const pickedData = useMemo(() => allTransactions?.[pickedTx.chainId]?.[pickedTx.tx], [allTransactions, pickedTx])

  const modalTitle = useMemo(() => {
    const title = ''

    /*
    if (pickedData?.nonBscFarm) {
      const { type, status } = pickedData?.nonBscFarm || {}
      const isPending = status === FarmTransactionStatus.PENDING
      if (type === NonBscFarmStepType.STAKE) {
        title = isPending ? t('Staking') : t('Staked!')
      } else if (type === NonBscFarmStepType.UNSTAKE) {
        title = isPending ? t('Unstaking') : t('Unstaked!')
      }
    }
      */

    return title
  }, [])

  return (
    <Modal title={modalTitle} onDismiss={onDismiss}>
      <ModalBody width={['100%', '100%', '100%', '352px']}>
        <Flex flexDirection="column">
          {/* 
          <FarmInfo pickedData={pickedData} />
          <LightGreyCard padding="16px 16px 0 16px">
            {pickedData?.nonBscFarm?.steps.map((step) => (
              <FarmDetail key={step.step} step={step} status={pickedData?.nonBscFarm?.status} />
            ))}
          </LightGreyCard>
          */}
        </Flex>
      </ModalBody>
    </Modal>
  )
}

export default FarmTransactionModal
