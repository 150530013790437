import { ChainId } from '@pancakeswap/chains'
import { getFarmsPriceHelperLpFiles } from '@pancakeswap/farms/constants/priceHelperLps/getFarmsPriceHelperLpFiles'
import PoolsBscPriceHelper from './pools/56'

export { getFarmsPriceHelperLpFiles }

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.PULSECHAIN:
      return PoolsBscPriceHelper
    default:
      return []
  }
}
