import { FarmConfigV3, SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedLps: FarmConfigV3[] = []

export const farmsV3 = defineFarmV3Configs([])

const farms: SerializedFarmConfig[] = []

export default farms
