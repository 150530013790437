import { ChainId } from '@pancakeswap/chains'
import { Address } from 'wagmi'

export const WBETH: Record<number, Address> = {
  [ChainId.PULSECHAIN]: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
}

export const SNBNB: Record<number, Address> = {}

export const UNWRAPPED_ETH_ADDRESS: Address = '0x79973d557CD9dd87eb61E250cc2572c990e20196'
