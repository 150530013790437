import { ChainId } from '@pancakeswap/chains'
import uniq from 'lodash/uniq'

export const supportedChainIdV2 = [] as const
export const supportedChainIdV3 = [] as const
export const supportedChainId = uniq([...supportedChainIdV2, ...supportedChainIdV3])
export const bCakeSupportedChainId = [ChainId.PULSECHAIN] as const

export const FARM_AUCTION_HOSTING_IN_SECONDS = 691200

export type FarmSupportedChainId = (typeof supportedChainId)[number]

export type FarmV2SupportedChainId = (typeof supportedChainIdV2)[number]

export type FarmV3SupportedChainId = (typeof supportedChainIdV3)[number]

export const masterChefAddresses = {
  [ChainId.PULSECHAIN]: '0x',
} as const

export const masterChefV3Addresses = {
  [ChainId.PULSECHAIN]: '0x',
} as const satisfies Record<FarmV3SupportedChainId, string>

export const nonBSCVaultAddresses = {} as const
