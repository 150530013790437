import { ChainId } from '@pancakeswap/chains';
import { bscTokens } from '@pancakeswap/tokens';
import { FeeAmount } from '@pancakeswap/v3-sdk';
import { getContract as getContract$1, keccak256, encodePacked, zeroAddress } from 'viem';

// src/abis/calcGaugesVoting.ts
var calcGaugesVotingABI = [
  {
    inputs: [],
    name: "gaugeVotingAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeRelativeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_gaugeId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeRelativeWeightById",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_gaugeId", type: "uint256" }],
    name: "getGaugeWeightDetails",
    outputs: [
      { internalType: "uint256", name: "gaugeWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeTotalWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeRawPercent", type: "uint256" },
      { internalType: "uint256", name: "gaugeCappedPercent", type: "uint256" },
      { internalType: "uint256", name: "gaugeInCapWeight", type: "uint256" },
      { internalType: "uint256", name: "gaugeTotalFinalWeights", type: "uint256" },
      { internalType: "uint256", name: "gaugeFinalPercent", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gaugeAddr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" },
      { internalType: "uint256", name: "_gaugeTotalWeight", type: "uint256" },
      { internalType: "uint256", name: "_gaugeTotalCappedPercent", type: "uint256" },
      { internalType: "uint256", name: "_gaugeTotalFinalWeights", type: "uint256" }
    ],
    name: "getGaugeWeightMass",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_gaugeId", type: "uint256" },
      { internalType: "bool", name: "_inCap", type: "bool" }
    ],
    name: "getGaugeWeightbyId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getRawTotalGaugeWeight",
    outputs: [{ internalType: "uint256", name: "gaugeTotalWeight", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getTotalCappedPercent",
    outputs: [{ internalType: "uint256", name: "gaugeTotalCappedPercent", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getTotalFinalWeights",
    outputs: [{ internalType: "uint256", name: "gaugeTotalFinalWeights", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "_inCap", type: "bool" }],
    name: "getTotalWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "_inCap", type: "bool" }],
    name: "massGetGaugeWeight",
    outputs: [{ internalType: "uint256[]", name: "result", type: "uint256[]" }],
    stateMutability: "view",
    type: "function"
  }
];

// src/abis/gaugesVoting.ts
var gaugesVotingABI = [
  {
    inputs: [{ internalType: "address", name: "_votingEscrow", type: "address" }],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "string", name: "name", type: "string" },
      { indexed: false, internalType: "uint256", name: "type_id", type: "uint256" }
    ],
    name: "AddType",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: false, internalType: "uint256", name: "allocation", type: "uint256" }
    ],
    name: "AdminAllocationChanged",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: false, internalType: "uint256", name: "period", type: "uint256" }
    ],
    name: "AdminOnlyPeriodUpdated",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "gauage_addr", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" }
    ],
    name: "GaugeKilled",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "gauage_addr", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" }
    ],
    name: "GaugeUnKilled",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "gauge_type", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "pid", type: "uint256" },
      { indexed: false, internalType: "address", name: "masterChef", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    name: "NewGauge",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "total_weight", type: "uint256" }
    ],
    name: "NewGaugeWeight",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "type_id", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "total_weight", type: "uint256" }
    ],
    name: "NewTypeWeight",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
      { indexed: true, internalType: "address", name: "newOwner", type: "address" }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Paused",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Unpaused",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "pid", type: "uint256" },
      { indexed: false, internalType: "address", name: "masterChef", type: "address" },
      { indexed: false, internalType: "uint256", name: "chainId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    name: "UpdateGaugeInfo",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "address", name: "user", type: "address" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "VoteForGauge",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "time", type: "uint256" },
      { indexed: false, internalType: "address", name: "user", type: "address" },
      { indexed: false, internalType: "bytes32", name: "hash", type: "bytes32" },
      { indexed: false, internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "VoteForGaugeFromAdmin",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: false, internalType: "uint256", name: "delay", type: "uint256" }
    ],
    name: "WeightVoteDelayChanged",
    type: "event"
  },
  {
    inputs: [],
    name: "ADMIN_VOTE_PERIOD",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "WEIGHT_VOTE_DELAY",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "gauge_type", type: "uint256" },
      { internalType: "uint256", name: "_weight", type: "uint256" },
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "address", name: "_masterChef", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "uint256", name: "_boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "_maxVoteCap", type: "uint256" }
    ],
    name: "addGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "string", name: "_name", type: "string" },
      { internalType: "uint256", name: "_weight", type: "uint256" }
    ],
    name: "addType",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "adminAllocation",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "adminSlopes",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_numerator", type: "uint256" }],
    name: "changeAdminAllocation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "weight", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "changeGaugeWeight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "type_id", type: "uint256" },
      { internalType: "uint256", name: "weight", type: "uint256" }
    ],
    name: "changeTypeWeight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_delay", type: "uint256" }],
    name: "changeWeightVoteDelay",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  { inputs: [], name: "checkpoint", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "checkpointGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeChangesWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "gaugeCount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeIndex_",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeIsKilled_",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugePointsTotal",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes32", name: "", type: "bytes32" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugePointsWeight",
    outputs: [
      { internalType: "uint256", name: "bias", type: "uint256" },
      { internalType: "uint256", name: "slope", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "time", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "gaugeRelativeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "time", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "gaugeRelativeWeight_write",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypeChangesSum",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeNames",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypePointsSum",
    outputs: [
      { internalType: "uint256", name: "bias", type: "uint256" },
      { internalType: "uint256", name: "slope", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "gaugeTypePointsWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gaugeTypeSumLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "gaugeTypes",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "gaugeTypes_",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "gauges",
    outputs: [
      { internalType: "uint256", name: "pid", type: "uint256" },
      { internalType: "address", name: "masterChef", type: "address" },
      { internalType: "uint256", name: "chainId", type: "uint256" },
      { internalType: "address", name: "pairAddress", type: "address" },
      { internalType: "uint256", name: "boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "maxVoteCap", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "inCap", type: "bool" }
    ],
    name: "getGaugeWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bool", name: "inCap", type: "bool" }],
    name: "getTotalWeight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "_typeId", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "getTypeAndChainIdWeightCapped",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_typeId", type: "uint256" }],
    name: "getWeightsSumPerType",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "killGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes32", name: "", type: "bytes32" }
    ],
    name: "lastUserVote",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function"
  },
  { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [],
    name: "totalLastScheduled",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "unkillGauge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "_newAdminOnlyPeriod", type: "uint256" }],
    name: "updateAdminOnlyPeriod",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "gauge_addr", type: "address" },
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "address", name: "_masterChef", type: "address" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "uint256", name: "_boostMultiplier", type: "uint256" },
      { internalType: "uint256", name: "_maxVoteCap", type: "uint256" }
    ],
    name: "updateGaugeInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_user_weight", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" },
      { internalType: "bool", name: "_skipNative", type: "bool" },
      { internalType: "bool", name: "_skipProxy", type: "bool" }
    ],
    name: "voteForGaugeWeights",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address[]", name: "_gauge_addrs", type: "address[]" },
      { internalType: "uint256[]", name: "_user_weights", type: "uint256[]" },
      { internalType: "uint256[]", name: "_chainIds", type: "uint256[]" },
      { internalType: "bool", name: "_skipNative", type: "bool" },
      { internalType: "bool", name: "_skipProxy", type: "bool" }
    ],
    name: "voteForGaugeWeightsBulk",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_gauge_addr", type: "address" },
      { internalType: "uint256", name: "_admin_weight", type: "uint256" },
      { internalType: "uint256", name: "_end", type: "uint256" },
      { internalType: "uint256", name: "_chainId", type: "uint256" }
    ],
    name: "voteFromAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address[]", name: "_gauge_addrs", type: "address[]" },
      { internalType: "uint256[]", name: "_admin_weights", type: "uint256[]" },
      { internalType: "uint256[]", name: "_ends", type: "uint256[]" },
      { internalType: "uint256[]", name: "_chainIds", type: "uint256[]" }
    ],
    name: "voteFromAdminBulk",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "voteUserPower",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes32", name: "", type: "bytes32" }
    ],
    name: "voteUserSlopes",
    outputs: [
      { internalType: "uint256", name: "slope", type: "uint256" },
      { internalType: "uint256", name: "power", type: "uint256" },
      { internalType: "uint256", name: "end", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "votingEscrow",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  }
];
var GAUGES = "0xf81953dC234cdEf1D6D0d3ef61b232C6bCbF9aeF";
var GAUGES_ADDRESS = {
  [ChainId.PULSECHAIN]: GAUGES
};
var GAUGES_CALC_ADDRESS = {
  [ChainId.PULSECHAIN]: "0x94F8cBa8712B3E72C9BF8Ba4d6619Ac9046FE695"
};

// src/types.ts
var GaugeType = /* @__PURE__ */ ((GaugeType2) => {
  GaugeType2[GaugeType2["StableSwap"] = -1] = "StableSwap";
  GaugeType2[GaugeType2["V2"] = 0] = "V2";
  GaugeType2[GaugeType2["V3"] = 1] = "V3";
  GaugeType2[GaugeType2["ALM"] = 2] = "ALM";
  GaugeType2[GaugeType2["VeCakePool"] = 3] = "VeCakePool";
  GaugeType2[GaugeType2["Aptos"] = 4] = "Aptos";
  return GaugeType2;
})(GaugeType || {});
var GAUGE_TYPE_NAMES = {
  [-1 /* StableSwap */]: "StableSwap",
  [0 /* V2 */]: "V2",
  [1 /* V3 */]: "V3",
  [2 /* ALM */]: "ALM",
  [3 /* VeCakePool */]: "VeCakePool",
  [4 /* Aptos */]: "Aptos"
};

// src/constants/config/prod.ts
var CONFIG_PROD = [
  {
    gid: 0,
    address: "0x133B3D95bAD5405d14d53473671200e9342896BF",
    pairName: "CAKE-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.cake.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 1,
    address: "0x7f51c8AaA6B0599aBd16674e2b17FEc7a9f674A1",
    pairName: "CAKE-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.cake.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 2,
    address: "0x85FAac652b707FDf6907EF726751087F9E0b6687",
    pairName: "BUSD-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.busd.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 3,
    address: "0x36696169C63e42cd08ce11f5deeBbCeBae652050",
    pairName: "USDT-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.usdt.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 4,
    address: "0x369482C78baD380a036cAB827fE677C1903d1523",
    pairName: "BTCB-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 5,
    address: "0x46Cf1cF8c69595804ba91dFdd8d6b960c9B0a7C4",
    pairName: "BTCB-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 6,
    address: "0xD4dCA84E1808da3354924cD243c66828cf775470",
    pairName: "BTCB-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 7,
    address: "0xFC75f4E78bf71eD5066dB9ca771D4CcB7C1264E0",
    pairName: "BTCB-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 8,
    address: "0x7d05c84581f0C41AD80ddf677A510360bae09a5A",
    pairName: "ETH-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eth.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 9,
    address: "0x92b7807bF19b7DDdf89b706143896d05228f3121",
    pairName: "USDC-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.usdc.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 10,
    address: "0x22536030B9cE783B6Ddfb9a39ac7F439f568E5e6",
    pairName: "USDC-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.usdc.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 11,
    address: "0x4f3126d5DE26413AbDCF6948943FB9D0847d9818",
    pairName: "BUSD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.busd.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 12,
    address: "0x66E9AcBA7C3a82C8EE02fAF3E325Dd55D6581a8c",
    pairName: "ETH-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eth.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 13,
    address: "0x9f6EB6903C1277c8f02d71F8814dc9998199af1D",
    pairName: "CAKE-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.cake.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 14,
    address: "0x539e0EBfffd39e54A0f7E5F8FEc40ade7933A664",
    pairName: "USDC-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.usdc.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 15,
    address: "0x0E1893BEEb4d0913d26B9614B18Aea29c56d94b9",
    pairName: "LINK-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.link.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 16,
    address: "0xd15B00E81F98A7DB25f1dC1BA6E983a4316c4CaC",
    pairName: "XRP-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.xrp.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 17,
    address: "0x673516E510d702Ab5F2bBf0c6B545111a85f7ea7",
    pairName: "ADA-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ada.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 18,
    address: "0x62F0546cBcd684F7C394D8549119e072527C41Bc",
    pairName: "DOT-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.dot.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 19,
    address: "0x61837a8a78F42dC6cfEd457c4eC1114F5e2d90f4",
    pairName: "ankrETH-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ankrETH.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 20,
    address: "0xCf57DaADfBE05A04440C502967cE5209F64747eB",
    pairName: "ankrBNB-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ankrbnb.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 21,
    address: "0x07003daEbc432ecec26309cCd1391BBBF06cC890",
    pairName: "GQ-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.gq.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 22,
    address: "0xD10612A288Bd5024Db6a47663750996d176130Fe",
    pairName: "AXL-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.axl.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 23,
    address: "0x088464e4E8CC54BF91180cBb8c61C68AeCC74166",
    pairName: "MGP-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.mgp.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 24,
    address: "0xb4E9DeA6105089f15685508B8EF2e7f7F5A1B16D",
    pairName: "UNW-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.unw.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 25,
    address: "0xAE7a3D9bed2ba4ef9c134FF4BAeE33655AE5DE6f",
    pairName: "stkBNB-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.stkbnb.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 26,
    address: "0x2dA32920A775CF121004551AbC92F385B3C0Dab9",
    pairName: "CHAMP-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.champ.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 27,
    address: "0x63ca58e7c6bF06B06cBbEc2a83bf6AA8f8f9f77B",
    pairName: "ZBC-CAKE",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.zbc.address,
    token1Address: bscTokens.cake.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 28,
    address: "0x379044E32f5A162233E82de19DA852255d0951b8",
    pairName: "WBETH-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.wbeth.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 29,
    address: "0x6425bC30D0751aF5181fC74a50e760b0e4a19811",
    pairName: "EDU-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.edu.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 30,
    address: "0xFB5C2D2f2cF7741ba1A7Be2FfAbED248BD9b888e",
    pairName: "EDU-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.edu.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 31,
    address: "0x08eAbc3d13Fb4bdFFD1F42a5644C1c826aCF62c0",
    pairName: "PEEL-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.peel.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 32,
    address: "0x77B27c351B13Dc6a8A16Cc1d2E9D5e7F9873702E",
    pairName: "BNBX-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.bnbx.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 33,
    address: "0x729c9a7E1f642Eab4019dfb0d467f4a9838E7cbD",
    pairName: "GAL-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.gal.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 34,
    address: "0x4e1f9aDf96dBA6Dc09c973228c286568F1315ea8",
    pairName: "ID-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.id.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 35,
    address: "0x3Cb75d72401d2DBA4349C696824F1397277d6a11",
    pairName: "USH-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ush.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 36,
    address: "0xC2B3dbBF26d43617036b0ebA53aD2DBD945adebf",
    pairName: "C98-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.c98.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 37,
    address: "0xbba8f85c3cEDdF73dB4de17D31608d640Eaea416",
    pairName: "PEPE-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.pepe.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 38,
    address: "0x466e7d53e23620a24db23E2B1f8BD10Ff52116Cd",
    pairName: "CSIX-CAKE",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.csix.address,
    token1Address: bscTokens.cake.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 39,
    address: "0x7B1db35fbd95548777B9079527e8fa2a70fb2CE0",
    pairName: "agEUR-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eura.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 40,
    address: "0x73D69D55893d6c97DCA44AF2Aa85B688C0242d7f",
    pairName: "PLAY-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.play.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 41,
    address: "0xd881D9d0e0767719701305c614903F555d589586",
    pairName: "TUSD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.tusd.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 42,
    address: "0x436380Bd2404aBA3f20c0ea68F343f5a174532a7",
    pairName: "xALGO-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.xalgo.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 43,
    address: "0x85BeA4fBC57fA22b41Fb1632f0D9a6A99390fd0a",
    pairName: "PENDLE-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.pendle.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 44,
    address: "0x803036AC78752EF599EC75c500ac8B0Ac0bE67dF",
    pairName: "axlUSDC-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.axlusdc.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 45,
    address: "0xD0e226f674bBf064f54aB47F42473fF80DB98CBA",
    pairName: "ETH-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eth.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 46,
    address: "0x6bbc40579ad1BBD243895cA0ACB086BB6300d636",
    pairName: "BTCB-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 47,
    address: "0x6ee3eE9C3395BbD136B6076A70Cb6cFF241c0E24",
    pairName: "BTCB-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 48,
    address: "0x647D99772863e09f47435782cbb6C96eC4A75f12",
    pairName: "UNI-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.uni.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 49,
    address: "0xdf0c1C30e8C1aE3f189f6E6ef248d71977F7BE29",
    pairName: "TUSD-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.tusd.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 50,
    address: "0xBe43E64Cd61E0b9207A6beE93e2149317A604326",
    pairName: "BNB-lisUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.bnb.address,
    token1Address: bscTokens.hay.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 51,
    address: "0x06E2D4002a693812A6348c91A2bEccf4E926FF2f",
    pairName: "BTCB-lisUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.hay.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 52,
    address: "0xfdFCdE34d2038EBeDe62e95C65b1492C28c990C9",
    pairName: "ETH-lisUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eth.address,
    token1Address: bscTokens.hay.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 53,
    address: "0x9474e972F49605315763c296B122CBB998b615Cf",
    pairName: "slisBNB-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.snbnb.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 54,
    address: "0xD465D9C13C43003f5B874e0D96A6030336Ed50eB",
    pairName: "DCK-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.dck.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 55,
    address: "0xce6160bB594fC055c943F59De92ceE30b8c6B32c",
    pairName: "DOGE-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.doge.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 56,
    address: "0xE6be850a43ae64c68754845ea444DE7d3fe761AB",
    pairName: "OLE-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ole.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 57,
    address: "0x13f0D0dF1f347E7246Ba16866d2562Bb2B337d0c",
    pairName: "WMX-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.wmx.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 58,
    address: "0xd9d0aeD9822e3D7C67b9c13A18dE070f86cdF2e5",
    pairName: "DAR-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.dar.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 59,
    address: "0x3ccCef8d9D515eC7F59eb69aD06C22265cC95ea9",
    pairName: "unshETH-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.unshETH.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 60,
    address: "0x846BD025527c8427809E11D0B0a9cE50F149D5d5",
    pairName: "CYBER-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.cyber.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 61,
    address: "0x3B7761632240c4BBec6deE27E10d491De9AA669B",
    pairName: "GMT-USDC",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.gmt.address,
    token1Address: bscTokens.usdc.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 62,
    address: "0x77E4ba48091f23a8a54b3e2c72F17be58cC2D137",
    pairName: "LVL-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.lvl.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 63,
    address: "0x29e13e65E8e2160dBd391bA3cee55E7dDe2A386c",
    pairName: "SFUND-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.sfund.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 64,
    address: "0x4f55423de1049d3CBfDC72f8A40f8A6f554f92aa",
    pairName: "RACA-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.raca.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 65,
    address: "0x293c665b9b98Cd51D6454C72529Fe17A1Cf1f504",
    pairName: "HIGH-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.high.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 66,
    address: "0xbB87F33d9f43Ec3fe75502958408262e7043B2E8",
    pairName: "ARV-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.arv.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 67,
    address: "0x6dba30870BC46BD6d2289E1827Cc415F6Fa23E1a",
    pairName: "HFT-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.hft.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 68,
    address: "0x635c26c473BB0686D403247477CC648a7C6edc2e",
    pairName: "CHR-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.chr.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 69,
    address: "0x12E79eb21dCc5852F9c6AC1736d977312925da33",
    pairName: "lisUSD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.hay.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 70,
    address: "0xd8CF0de0387A9c16acF53384c89632678D77F311",
    pairName: "WNCG-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.wncg.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 71,
    address: "0x5a1Fe6D5026CC3736Cfb7316dbCFbf63D82cC789",
    pairName: "MBX-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.mbx.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 72,
    address: "0x66bAA9E43e64c8A85bC3c04dEBf9E7686BE5b09C",
    pairName: "MBX-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.mbx.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 73,
    address: "0x302e26e9bda986709B5F504D3426c2310e6383c6",
    pairName: "XCAD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.xcad.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 74,
    address: "0x64ebB904e169cB94e9788FcB68283B4C894ED881",
    pairName: "SFP-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.sfp.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 75,
    address: "0xE3cBe4Dd1BD2F7101f17D586F44bAb944091D383",
    pairName: "LTC-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.ltc.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 76,
    address: "0xB55A0B97B7D9Ebe4208b08AB00feC0C419cc32A3",
    pairName: "RDNT-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.rdnt.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 77,
    address: "0x0004222c2075E9A1291E41f1cA4C8d32141dB501",
    pairName: "MBOX-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.mbox.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 78,
    address: "0xDcccC7d0B02C837d1B8D8a8D5E2683387bc2b910",
    pairName: "WOM-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.wom.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 79,
    address: "0x81Bef404f5C93d99ed04Ed55488c99722CDd7A50",
    pairName: "AXS-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.axs.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 80,
    address: "0xa98D8a5867D664B7A758652146fd93a7dE40eE82",
    pairName: "TRX-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.trxv2.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 81,
    address: "0x77d5b2560e4B84b3fC58875Cb0133F39560e8AE3",
    pairName: "XVS-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.xvs.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 82,
    address: "0xE4e695FA53598dA586F798A9844A3b03d86f421e",
    pairName: "BTT-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btt.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 83,
    address: "0xcfe783e16c9a8C74F2be9BCEb2339769439061Bf",
    pairName: "ALPACA-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.alpaca.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 84,
    address: "0x832EeBF89F99aACcf6640fe6b5E838066c630Fbe",
    pairName: "CHESS-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.chess.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.HIGH
  },
  {
    gid: 85,
    address: "0x8cCB4544b3030dACF3d4D71C658f04e8688e25b1",
    pairName: "TWT-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.twt.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 86,
    address: "0xBe141893E4c6AD9272e8C04BAB7E6a10604501a5",
    pairName: "ETH-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.eth.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 87,
    address: "0xbF72B6485E4b31601aFe7B0a1210Be2004D2B1d6",
    pairName: "FDUSD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.fdusd.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.LOWEST
  },
  {
    gid: 88,
    address: "0x4BBA1018b967e59220b22Ca03f68821A3276c9a6",
    pairName: "BTCB-ETH",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.btcb.address,
    token1Address: bscTokens.eth.address,
    feeTier: FeeAmount.LOW
  },
  {
    gid: 89,
    address: "0x5F16320FA36559ca7bc785834Ba77105154DC40b",
    pairName: "STG-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.stg.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 90,
    address: "0x89A6be1ec107C911C3F2A1112f049F876Ce033c9",
    pairName: "STG-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 1 /* V3 */,
    token0Address: bscTokens.stg.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 91,
    address: "0x9cac9745731d1Cf2B483f257745A512f0938DD01",
    pairName: "CAKE Pool",
    chainId: ChainId.PULSECHAIN,
    type: 3 /* VeCakePool */
  },
  {
    gid: 92,
    address: "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
    pairName: "CAKE-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.cake.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 93,
    address: "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b",
    pairName: "CAKE-USDT",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.cake.address,
    token1Address: bscTokens.usdt.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 94,
    address: "0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2",
    pairName: "XVS-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.xvs.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 95,
    address: "0x346575fC7f07E6994D76199E41D13dC1575322E1",
    pairName: "RDNT-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.rdnt.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 96,
    address: "0x8FA59693458289914dB0097F5F366d771B7a7C3F",
    pairName: "MBOX-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.mbox.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 97,
    address: "0x942b294e59a8c47a0F7F20DF105B082710F7C305",
    pairName: "SFP-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.sfp.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 98,
    address: "0xa0D4e270D9EB4E41f7aB02337c21692D7eECCCB0",
    pairName: "SABLE-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.sable.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 99,
    address: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
    pairName: "SFUND-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.sfund.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 100,
    address: "0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13",
    pairName: "TWT-WBNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.twt.address,
    token1Address: bscTokens.wbnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 101,
    address: "0xBCEA09e9e882eC2Bb6dCE07c4e6669968846CaBD",
    pairName: "STG-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.stg.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 102,
    address: "0xAf839f4D3620a1EED00cCc21dDC01119C26a75E1",
    pairName: "APX-BNB",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.apx.address,
    token1Address: bscTokens.bnb.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 103,
    address: "0xa0Ee789a8F581CB92dD9742ed0B5d54a0916976C",
    pairName: "APX-BUSD",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: bscTokens.apx.address,
    token1Address: bscTokens.busd.address,
    feeTier: FeeAmount.MEDIUM
  },
  {
    gid: 104,
    address: "0xb1Da7D2C257c5700612BdE35C8d7187dc80d79f1",
    pairName: "lisUSD-USDT",
    chainId: ChainId.PULSECHAIN,
    type: -1 /* StableSwap */,
    tokenAddresses: [bscTokens.hay.address, bscTokens.usdt.address]
  }
];

// src/constants/config/index.ts
var GAUGES_CONFIG = {
  [ChainId.PULSECHAIN]: CONFIG_PROD
};
var CONFIG_TESTNET = [
  {
    gid: 0,
    address: "0x523405d3072368BEe373F04EE738f2Aa3e3718c6",
    chainId: ChainId.PULSECHAIN,
    type: 0 /* V2 */,
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token1Address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    pairName: "CAKE-WBNB",
    feeTier: FeeAmount.MEDIUM
  }
];
var getContract = (client) => {
  const chainId = client.chain?.id;
  if (!chainId || !Object.keys(GAUGES_ADDRESS).includes(String(chainId))) {
    throw new Error(`Invalid client chain ${client.chain?.id}`);
  }
  return getContract$1({
    address: GAUGES_ADDRESS[chainId],
    abi: gaugesVotingABI,
    publicClient: client
  });
};
var getCalcContract = (client) => {
  const chainId = client.chain?.id;
  if (!chainId || !Object.keys(GAUGES_CALC_ADDRESS).includes(String(chainId))) {
    throw new Error(`Invalid client chain ${client.chain?.id}`);
  }
  return getContract$1({
    address: GAUGES_CALC_ADDRESS[chainId],
    abi: calcGaugesVotingABI,
    publicClient: client
  });
};

// src/fetchGaugesCount.ts
var fetchGaugesCount = async (client, options) => {
  const contract = getContract(client);
  const count = await contract.read.gaugeCount(options);
  return Number(count || 0);
};
var getGaugeHash = (gaugeAddress = zeroAddress, chainId = 0) => {
  return keccak256(encodePacked(["address", "uint256"], [gaugeAddress, BigInt(chainId || 0)]));
};

// src/fetchAllGauges.ts
var fetchAllGauges = async (client, options) => {
  const contract = getContract(client);
  const counts = await fetchGaugesCount(client, options);
  const multicalls = [];
  for (let i = 0; i < counts; i++) {
    multicalls.push({
      ...contract,
      functionName: "gauges",
      args: [BigInt(i)]
    });
  }
  const response = await client.multicall({
    contracts: multicalls,
    allowFailure: false,
    ...options
  });
  return response.reduce((prev, curr) => {
    const [pid, masterChef, chainId, pairAddress, boostMultiplier, maxVoteCap] = curr;
    return [
      ...prev,
      {
        pid: Number(pid),
        hash: getGaugeHash(pairAddress, Number(chainId)),
        pairAddress,
        masterChef,
        chainId: Number(chainId),
        boostMultiplier: Number(boostMultiplier),
        maxVoteCap: Number(maxVoteCap)
      }
    ];
  }, []);
};

// src/fetchAllKilledGauges.ts
var fetchAllKilledGauges = async (client, gauges, options) => {
  const contract = getContract(client);
  const multicalls = [];
  for (let i = 0; i < gauges.length; i++) {
    multicalls.push({
      ...contract,
      functionName: "gaugeIsKilled_",
      args: [gauges[i].hash]
    });
  }
  const response = await client.multicall({
    contracts: multicalls,
    allowFailure: false,
    ...options
  });
  return gauges.map((gauge, index) => {
    if (response[index] === true) {
      return {
        ...gauge,
        killed: response[index]
      };
    }
    return gauge;
  });
};

// src/fetchGaugeVoting.ts
var fetchAllGaugesVoting = async (client, gaugeInfos, inCap = true, options) => {
  const contract = getCalcContract(client);
  const weights = await contract.read.massGetGaugeWeight([inCap], options);
  return gaugeInfos.map((gauge) => ({
    ...gauge,
    weight: weights[gauge.gid] ?? 0n
  }));
};

// src/getAllGauges.ts
var getAllGauges = async (client, options = {
  testnet: false,
  inCap: true,
  bothCap: false,
  killed: false
}) => {
  const { testnet, inCap, bothCap, killed, blockNumber } = options;
  const presets = testnet ? CONFIG_TESTNET : CONFIG_PROD;
  const allGaugeInfos = await fetchAllGauges(client, {
    blockNumber
  });
  let allActiveGaugeInfos = allGaugeInfos;
  allActiveGaugeInfos = await fetchAllKilledGauges(client, allGaugeInfos, { blockNumber });
  if (!killed)
    allActiveGaugeInfos = allGaugeInfos.filter((gauge) => !gauge.killed);
  const allGaugeInfoConfigs = allActiveGaugeInfos.reduce((prev, gauge) => {
    const filters = presets.filter((p) => p.address === gauge.pairAddress && Number(p.chainId) === gauge.chainId);
    let preset;
    if (!filters.length)
      return prev;
    if (filters.length > 1) {
      preset = filters[filters.length - 1];
    } else {
      preset = filters[0];
    }
    return [
      ...prev,
      {
        ...preset,
        ...gauge
      }
    ];
  }, []);
  if (!bothCap) {
    const allGaugesVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, inCap, options);
    return allGaugesVoting;
  }
  const inCapVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, true, options);
  const notInCapVoting = await fetchAllGaugesVoting(client, allGaugeInfoConfigs, false, options);
  return inCapVoting.reduce((prev, inCapGauge) => {
    const notInCapGauge = notInCapVoting.find((p) => p.hash === inCapGauge.hash);
    return [
      ...prev,
      {
        ...inCapGauge,
        weight: 0n,
        inCapWeight: inCapGauge.weight,
        notInCapWeight: notInCapGauge?.weight
      }
    ];
  }, []);
};

export { GAUGES_ADDRESS, GAUGES_CALC_ADDRESS, GAUGES_CONFIG, GAUGE_TYPE_NAMES, GaugeType, calcGaugesVotingABI, gaugesVotingABI, getAllGauges };
