import { ChainId } from '@pancakeswap/chains'
import { getNodeRealUrl } from 'utils/node/nodeReal'
import { getGroveUrl } from 'utils/node/pokt'
import { pulsechain } from 'wagmi/chains'

export const SERVER_NODES = {
  [ChainId.PULSECHAIN]: pulsechain.rpcUrls.public.http,
} satisfies Record<ChainId, readonly string[]>

export const PUBLIC_NODES = {
  [ChainId.PULSECHAIN]: pulsechain.rpcUrls.public.http,
} satisfies Record<ChainId, readonly string[]>
